<template>
    <div class="upload_sample">
        <div class="upload_sample_container">
            <div class="upload_sample_container_title">
                <p><span>Upload sample information</span></p>
                <img class="upload_sample_container_title_close"
                    src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog">
            </div>

            <div class="upload_sample_container_form">

                <div class="upload_sample_container_form_item1">
                    <el-form ref="form" :model="form1" >

                        <!-- 上传图片 -->
                        <el-form-item label="Upload images" prop="images">
                            <el-upload :file-list="fileList"
                                       :action="uploadFileUrl"
                                       :headers="headers"
                                       :on-error="handleUploadError"
                                       :before-upload="beforeUpload"
                                       :on-remove="handleRemove"
                                       :on-success="handleAvatarSuccess"
                                list-type="picture-card" >
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>

                        <!-- Mode of transport -->
                        <el-form-item label="Express company" prop="expressCompany">
                          <el-input v-model="form1.expressCompany" placeholder="Enter Tracking number"></el-input>
                        </el-form-item>

                        <el-form-item label="Tracking number" prop="trackingNumber">
                            <el-input v-model="form1.trackingNumber" placeholder="Enter Tracking number"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="submitForm">Confirm</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <el-dialog v-model="dialogVisible">
                <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
        </div>
    </div>
</template>

<script setup name="UploadSampleDetail">
import { ref, defineProps, defineEmits } from 'vue'

// 关闭弹窗
import { getQuotationDetail, getTransportationInfo, saveTransportationInfo } from '@/api/quotation'
import { target } from '@/utils/URL'
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
const form1 = ref({
  expressCompany: '',
  trackingNumber: ''
})

// 上传图片

const fileList = ref([])

const dialogImageUrl = ref('')
const dialogVisible = ref(false)

// 上传文件

const uploadFileUrl = ref(target + 'common/uploadNotLogin') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})

const beforeUpload = (file) => {
  /* const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    ElMessage.error('上传头像图片大小不能超过 2MB!')
  }
  return isLt2M */
  return true
}

const attachmentList = ref([])
const handleRemove = (uploadFile, uploadFiles) => {
  attachmentList.value = []
  console.log(uploadFiles)
  for (const e of uploadFiles) {
    if (e.response) {
      const entity = {
        attachmentName: e.response.originalFilename,
        attachment: e.response.url
      }
      attachmentList.value.push(entity)
    } else {
      const entity = {
        attachmentName: e.attachmentName,
        attachment: e.attachment
      }
      attachmentList.value.push(entity)
    }
  }
}
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  console.log(uploadFiles)
  attachmentList.value = []
  for (const e of uploadFiles) {
    if (e.response) {
      const entity = {
        attachmentName: e.response.originalFilename,
        attachment: e.response.url
      }
      attachmentList.value.push(entity)
    } else {
      const entity = {
        attachmentName: e.attachmentName,
        attachment: e.attachment
      }
      attachmentList.value.push(entity)
    }
  }
}

// 上传图片结束

const props = defineProps({
  quotationId: Number
})
const getQuoInfo = async () => {
  const res = await getQuotationDetail(props.quotationId)
  if (res.code === 200) {
    form1.value.expressCompany = res.data.expressCompany
    form1.value.trackingNumber = res.data.trackingNumber
  }
}
getQuoInfo()

const getTrantationInfo = async () => {
  const res = await getTransportationInfo({ quotationId: props.quotationId })
  if (res.code === 200) {
    form1.value.expressCompany = res.data.expressCompany
    form1.value.trackingNumber = res.data.trackingNumber
    if (res.data.attachmentList.length > 0) {
      for (const e of res.data.attachmentList) {
        e.name = e.attachmentName
        e.url = e.attachment
      }
    }
    fileList.value = res.data.attachmentList
  }
}
getTrantationInfo()

// 上面是表单数据

const submitForm = async () => {
  form1.value.attachmentList = attachmentList.value
  form1.value.quotationId = props.quotationId
  const res = await saveTransportationInfo(form1.value)
  if (res.code === 200) {
    CloseDialog()
  } else {
    ElMessage({
      message: res.msg,
      type: 'error'
    })
  }
}
const emit = defineEmits(['customEvent'])
const CloseDialog = () => {
  emit('customEvent', 'close')
}

</script>

<style lang="scss" scoped>
.upload_sample {

    .upload-demo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: hidden;
    }

    .upload-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-icon {
        font-size: 28px;
    }

    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    line-height: 1;
    text-align: left;
    overflow-y: scroll;

    &_container {
        width: 890px;
        margin: 80px auto 0;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 300px;

        &_title {
            display: flex;
            justify-content: space-between;
            padding: 30px;
            background-color: rgba(248, 248, 248, 1);

            P {
                font-size: 20px;
                margin: 0;
                font-weight: 700;
                color: rgba(24, 24, 24, 1);
            }

            &_close {
                width: 20px;
                height: 20px;
                cursor: pointer;

            }
        }

        &_form {
            margin-top: 50px;

            &_item1 {
                ::v-deep .el-form {
                    width: 730px;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .el-form-item {
                        margin-bottom: 30px;
                        width: 350px;
                        display: flex;
                        flex-direction: column;

                        .el-form-item__label {
                            width: 340px;
                            justify-content: flex-start;
                            font-size: 14px;
                            line-height: 1;
                            height: 14px;
                            margin-bottom: 10px;
                            color: rgba(24, 24, 24, 1);
                            font-weight: 300;
                        }

                        .el-form-item__content {
                            .el-input {
                                height: 46px;

                                .el-input__wrapper {
                                    padding: 0px 20px;
                                    box-shadow: none;
                                    background-color: rgba(249, 249, 249, 1);

                                    .el-input__inner {
                                        color: rgba(24, 24, 24, 1);
                                        font-size: 16px;

                                        &::placeholder {
                                            color: rgba(173, 173, 173, 0.6);
                                        }
                                    }
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }
                            }

                            .el-select {
                                width: 350px;

                                .el-input__wrapper.is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1) !important;
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                            }
                        }

                        .el-button {
                            border-radius: 4px;
                            background-color: rgba(39, 174, 96, 1);
                            box-shadow: none;
                            border: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 700;
                            padding: 27px 30px;
                        }

                        &:last-child {
                            width: 890px;
                            justify-content: center;
                            align-items: center;
                            margin: 70px auto 80px;
                        }

                        &:first-child {
                            width: 890px;
                            justify-content: center;
                            margin: 0px auto 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
