<template>
    <div class="upload_detail">
        <div class="upload_detail_container">
            <div class="upload_detail_container_title">
                <p>
                    <span :class="[uploadDetailStep == 1 ? 'active' : '']" @click="prevStep">1.Confirm carbon
                        footprint</span>
                    <span :class="[uploadDetailStep == 2 ? 'active' : '']" @click="nextStep">2.Upload shipment detail</span>
                </p>
                <img class="upload_detail_container_title_close"
                    src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog">
            </div>

            <!-- 大货单第 1 步，确认信息 -->
            <div class="upload_detail_container_form" v-if="uploadDetailStep == 1">
                <div class="upload_detail_container_form_item item1">
                    <el-form ref="form" :model="form1" >

                      <div class="el-form-line2">Material Usage</div>
                      <div class="add_material">
                        <el-button  type="info" plain @click="addData">Add Material</el-button>
                      </div>
                      <div v-for="(child,childIndex) in childList" :key="childIndex" class="materialList" style="width:800px;display: flex;align-items: center;">
                        <el-form-item label="Material" prop="material">
                          <el-select
                            v-model="child.materialId"
                            placeholder="Select Material"
                          >
                            <el-option
                              v-for="(item, index) in materials"
                              :key="index"
                              :label="item.content"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <!-- Material Usage -->
                        <el-form-item label="Material Usage (g)" prop="materialUsage" style="margin-left: 20px">
                          <el-input
                            v-model="child.materialUsage"
                            placeholder="Enter Material Usage"
                            type=number
                          ></el-input>
                        </el-form-item>
                        <el-button style="margin-left: 10px"  type="info" plain @click="delData(childIndex)">Delete</el-button>
                      </div>

                        <!-- Mode of production -->
                        <el-form-item label="Mode of production" prop="modeOfProduction">
                          <el-select
                            v-model="form1.productionMode"
                            placeholder="Select Mode of Production"
                          >
                            <el-option
                              v-for="(item, index) in productModeList"
                              :key="index"
                              :label="item.catalog2"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <!-- Energy used -->
                        <el-form-item label="Energy used (KWH)" prop="energyuse">
                          <el-input
                            v-model="form1.energyuse"
                            placeholder="Enter Energy Usage"
                            type="number"
                          ></el-input>
                        </el-form-item>

                        <div class="el-form-line2">Material <img
                                src="../../../assets/images/supplier/quotation/arrow-right.png" alt=""><span>Factory</span>
                        </div>

                      <!-- 原料地点 -->
                      <!-- Raw material location 输入-->
                      <el-form-item
                        label="Raw material location"
                        prop="rawMaterialLocation"
                      >
                        <el-input
                          v-model="form1.rawMaterialLocation"
                          placeholder="Enter Raw material location"
                          @focus="openAmap('material')"
                        ></el-input>
                      </el-form-item>
                      <!-- Factory Location -->
                      <el-form-item
                        label="Factory Location"
                        prop="factoryLocation"
                      >
                        <el-input
                          v-model="form1.factoryLocation"
                          placeholder="Enter Factory Location"
                          @focus="openAmap('Factory')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item
                        label="Distance (km)"
                        prop="materialDistance"
                      >
                        <el-input
                          disabled
                          v-model="form1.materialDistance"
                        ></el-input>
                      </el-form-item>
                      <!-- Mode of transport -->
                      <el-form-item
                        label="Mode of transport"
                        prop="materialTransportationType"
                        v-if="1 > 2"
                      >
                        <el-select
                          v-model="form1.materialTransportationType"
                          placeholder="Select Mode of Transport"
                        >
                          <el-option
                            v-for="(item, index) in modeOfTransports"
                            :key="index"
                            :label="item.type"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                        <div class="el-form-line2">Factory <img
                                src="../../../assets/images/supplier/quotation/arrow-right.png" alt=""><span>Port of
                                departure</span></div>

                      <!-- Port of departure -->
                      <el-form-item
                        label="Port of departure"
                        prop="port"
                      >
                        <el-select
                          v-model="form1.port"
                          placeholder="Select Port of Departure"
                          @change="portChange"
                        >
                          <el-option
                            v-for="item in Object.keys(portLocations)"
                            :key="item"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item
                        label="Distance (km)"
                        prop="distance"
                      >
                        <el-input
                          disabled
                          v-model="form1.distance"
                        ></el-input>
                      </el-form-item>

                      <!-- Mode of transport (Duplicate?) -->
                      <el-form-item
                        label="Mode of transport"
                        prop="transportationType"
                        v-if="1 > 2"
                      >
                        <el-select
                          v-model="form1.transportationType"
                          placeholder="Select Mode of Transport"
                        >
                          <el-option
                            v-for="(item, index) in modeOfTransports2"
                            :key="index"
                            :label="item.type"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <div class="el-form-line2">International <img
                        src="../../../assets/images/supplier/quotation/arrow-right.png"
                        alt=""
                      ><span>transport mode</span></div>
                      <el-form-item
                        label="Mode of transport"
                        prop="internationalTransportationType"
                      >
                        <el-select
                          v-model="form1.internationalTransportationType"
                          placeholder="Select Mode of Transport"
                        >
                          <el-option
                            v-for="(item, index) in modeOfTransports2"
                            :key="index"
                            :label="item.type"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                        <!-- 确认信息 -->
                        <el-form-item>
                            <el-button type="primary" @click="confirmForm">Confirm and start filling in shipment
                                detail</el-button>
                        </el-form-item>
                    </el-form>
                </div>

            </div>

            <!-- 大货单第 2 步，上传信息 -->
            <div class="upload_detail_container_form" v-if="uploadDetailStep == 2">
                <div class="upload_detail_container_form_item item2">
                    <el-form ref="form" :model="form2"  :rules="rules">
                        <!-- Shipping company 下拉选择 -->
                        <el-form-item label="Shipping company" prop="shippingCompany">
                          <el-input v-model="form2.shippingCompany" placeholder="Enter Ship Company"></el-input>
                        </el-form-item>

                        <!-- Ship name -->
                        <el-form-item label="Ship name" prop="shipName">
                            <el-input v-model="form2.shipName" placeholder="Enter Ship Name"></el-input>
                        </el-form-item>

                        <!-- Bill of Lading Number -->
                        <el-form-item label="Bill of Lading Number" prop="billOfLadingNumber">
                            <el-input v-model="form2.billOfLadingNumber"
                                placeholder="Enter Bill of Lading Number"></el-input>
                        </el-form-item>

                        <!-- Helvetica-light -->
                        <el-form-item label="Helvetica-light" prop="helveticaLight">
                            <el-input v-model="form2.helveticaLight" placeholder="Enter Helvetica-light"></el-input>
                        </el-form-item>

                        <!-- Seal Number -->
                        <el-form-item label="Seal Number" prop="sealNumber">
                            <el-input v-model="form2.sealNumber" placeholder="Enter Seal Number"></el-input>
                        </el-form-item>

                        <!-- ETD 选择日期 -->
                        <el-form-item label="ETD" prop="ETD">
                            <el-date-picker v-model="form2.etd" type="date" placeholder="Select ETD"  format="YYYY-MM-DD"
                                            value-format="YYYY-MM-DD"></el-date-picker>
                        </el-form-item>

                        <!-- ETA 选择日期 -->
                        <el-form-item label="ETA" prop="ETA">
                            <el-date-picker v-model="form2.eta" type="date" placeholder="Select ETA"  format="YYYY-MM-DD"
                                            value-format="YYYY-MM-DD"></el-date-picker>
                        </el-form-item>

                        <el-form-item label="Upload qualification certificate" prop="uploadQualificationCertificate">
                            <el-upload drag
                                       :action="uploadFileUrl"
                                       :headers="headers"
                                       :on-error="handleUploadError"
                                       :before-upload="beforeUpload"
                                       :on-remove="handleRemove"
                                       :on-success="handleAvatarSuccess"  :file-list="fileList">
                                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            </el-upload>
                        </el-form-item>

                        <!-- 提交信息 -->
                        <el-form-item>
                            <el-button class="back_btn" type="primary" @click="prevStep">Back</el-button>
                            <el-button type="primary" @click="uploadDetail">Upload</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </div>
    </div>
  <Amap
    v-on:customEvent="amapCustomEvent"
    v-if="showAMap"
  />
</template>

<script setup name="UploadShipmentDetail">
import { inject, ref, defineEmits, defineProps } from 'vue'
import { getMaterialList } from '@/api/user'
import { getProductModeList } from '@/api/productmode'
import Amap from '../dialog/Amap.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { shallowRef } from '@vue/reactivity'
import { getTransportType } from '@/api/transport'
import { getQuotationDetail, getTransportationInfo, saveTransportationInfo, updateQuotation } from '@/api/quotation'
import { ElMessage } from 'element-plus'
import { target } from '@/utils/URL'
import { getToken } from '@/utils/auth'
const map = shallowRef(null)
// "key": "f4cd24293f5594ffcda56bad70dcabb6"
// 添加高德安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '5ca65c74bfbcc9922df08400c8dba2d5'
}
// 原料的经纬度
const materialLng = ref([])
// 工厂的经纬度
const factoryLng = ref([])
// 接收是否关闭地图弹窗
const showAMap = ref(false)
// 记录点击的哪个按钮
const whichBtn = ref('')
const openAmap = (val) => {
  whichBtn.value = val
  showAMap.value = true
}
const amapCustomEvent = (data) => {
  showAMap.value = false
  console.log('amapCustomEvent，弹窗数据', data.address.value, data.location.value)

  // 原料地点
  if (data.address.value !== '请点击地图选择具体地点' && whichBtn.value === 'material') {
    form1.value.rawMaterialLocation = data.address
    materialLng.value = data.location.value
    localtionChange()
  } else if (data.address.value !== '请点击地图选择具体地点' && whichBtn.value === 'Factory') {
    form1.value.factoryLocation = data.address
    factoryLng.value = data.location.value
    localtionChange()
  }
}

const childList = ref([
  {
    materialId: '',
    materialUsage: ''
  }
])
const childShow = ref(false)
const addData = () => {
  childShow.value = true
  childList.value.push({
    materialId: '',
    materialUsage: ''
  })
}

const delData = (index) => {
  if (childList.value.length === 1) {
    childShow.value = false
    ElMessage({
      message: 'Please fill in at least one!',
      type: 'error'
    })
    return false
  }
  childList.value.splice(index, 1)
}
// 第 1 步表单
const form1 = ref({
  hsCode: '',
  deliveryTime: '',
  sampleCharge: '',
  sampleLeadtime: '',
  pcsPerCtn: '',
  ctnSize: '',
  CBMCtn: '',
  weightCtn: '',
  totalCBM: '',
  grossWeight: '',
  FOBCIF: '',
  specialCharges: '',
  materialId: '',
  materialUsage: '',
  productionMode: '',
  modeOfProduction: '',
  energyuse: '',
  materialDistance: '',
  materialTransportationType: '3',
  port: '',
  distance: '',
  transportationType: '3',
  rawMaterialLocation: '',
  factoryLocation: '',
  internationalTransportationType: '',
  materialList: []
})
const productModeList = ref([])

const getProductModeLists = async () => {
  const res = await getProductModeList()
  if (res.code === 200) {
    productModeList.value = res.data
  }
}

const transTypes = ref([])
const getTransTypes = async () => {
  const result = await getTransportType()
  console.log('results===', result)
  if (result.code === 200) {
    transTypes.value = result.data
  }
}
getTransTypes()

const portLocations = ref({
  'PORT OF SHANGHAI': [121.471674, 30.841345],
  'PORT OF SHENZHEN': [113.838832, 22.485499],
  'PORT OF TIANJIN': [117.728848, 38.994634],
  'PORT OF NINGBO': [121.874531, 29.635993],
  'PORT OF QINGDAO': [120.354558, 36.064012],
  'PORT OF GUANGZHOU': [113.515403, 22.922798],
  'PORT OF XIANGGANG': [114.182386, 22.306274],
  'PORT OF FUZHOU': [119.28755, 26.056793],
  'PORT OF XIAMEN': [118.086166, 24.453685],
  'PORT OF DALLAN': [121.639284, 38.916666],
  'PORT OF NANJING': [118.871443, 31.968585]
})
const portChange = (val) => {
  console.log('选择了港口', val)
  console.log('portChange', val)

  if (factoryLng.value.length === 0) {

  } else {
    const realval = portLocations.value[val]
    initMap(factoryLng.value, realval).then(distance => {
      console.log('获取到工厂到港口的距离：' + distance + 'KM')
      form1.value.distance = distance
    }).catch(error => {
      console.log('获取距离失败：' + error)
    })
  }
}

// 计算原料到工厂的距离
const localtionChange = () => {
  console.log('选择了地点', materialLng.value, factoryLng.value)
  if (materialLng.value.length === 0 || factoryLng.value.length === 0) {

  } else {
    initMap(materialLng.value, factoryLng.value).then(distance => {
      console.log('获取到原料到工厂的距离：' + distance + 'KM')
      form1.value.materialDistance = distance
    }).catch(error => {
      console.log('获取距离失败：' + error)
    })
  }
}

// 计算距离
const initMap = (start, end) => {
  return new Promise((resolve, reject) => {
    AMapLoader.load({
      key: 'f4cd24293f5594ffcda56bad70dcabb6',
      version: '2.0',
      plugins: ['AMap.Driving']
    }).then((AMap) => {
      map.value = new AMap.Map('containerMap', {
        resizeEnable: true,
        center: [116.397428, 39.90923], // 地图中心点
        zoom: 5 // 地图显示的缩放级别
      })

      var driving = new AMap.Driving({
        map: map.value
      })
      driving.search(new AMap.LngLat(start[0], start[1]), new AMap.LngLat(end[0], end[1]), function (status, result) {
        if (status === 'complete') {
          // console.log('获取数据成功');
          const distance = result.routes[0].distance / 1000
          // console.log('距离：' + distance + 'KM')
          resolve(distance)
        } else {
          console.log('获取数据失败：' + result)
          reject(result)
        }
      })
    }).catch(e => {
      console.log(e)
      reject(e)
    })
  })
}

getProductModeLists()

const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  console.log('materials===', res)
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

const modeOfTransports = transTypes
const modeOfTransports2 = transTypes

// 第 2 步表单
const form2 = ref({
  shippingCompany: '',
  shipName: '',
  billOfLadingNumber: '',
  helveticaLight: '',
  sealNumber: '',
  etd: '',
  eta: '',
  attachmentList: []
})

// 上传文件

const uploadFileUrl = ref(target + 'common/uploadNotLogin') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})

const beforeUpload = (file) => {
  /* const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    ElMessage.error('上传头像图片大小不能超过 2MB!')
  }
  return isLt2M */
  return true
}

const fileList = ref([])
const attachmentList = ref([])
const handleRemove = (uploadFile, uploadFiles) => {
  attachmentList.value = []
  console.log(uploadFiles)
  for (const e of uploadFiles) {
    if (e.response) {
      const entity = {
        attachmentName: e.response.originalFilename,
        attachment: e.response.url
      }
      attachmentList.value.push(entity)
    } else {
      const entity = {
        attachmentName: e.attachmentName,
        attachment: e.attachment
      }
      attachmentList.value.push(entity)
    }
  }
}
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  console.log(uploadFiles)
  attachmentList.value = []
  for (const e of uploadFiles) {
    if (e.response) {
      const entity = {
        attachmentName: e.response.originalFilename,
        attachment: e.response.url
      }
      attachmentList.value.push(entity)
    } else {
      const entity = {
        attachmentName: e.attachmentName,
        attachment: e.attachment
      }
      attachmentList.value.push(entity)
    }
  }
}

// 上面是表单数据

// 接收父组件传递的数据
const counter = inject('counter')
console.log(counter)

const props = defineProps({
  quotationId: Number
})
const getQuoInfo = async () => {
  const res = await getQuotationDetail(props.quotationId)
  if (res.code === 200) {
    form1.value = res.data
    childList.value = res.data.materialList
  }
}
getQuoInfo()

const getTrantationInfo = async () => {
  const res = await getTransportationInfo({ quotationId: props.quotationId })
  if (res.code === 200) {
    if (res.data.attachmentList.length > 0) {
      for (const e of res.data.attachmentList) {
        e.name = e.attachmentName
        e.url = e.attachment
      }
    }
    form2.value = res.data
    fileList.value = res.data.attachmentList
  }
}
getTrantationInfo()

// 下一步
const nextStep = () => {
  uploadDetailStep.value = 2
}
// 上一步
const prevStep = () => {
  uploadDetailStep.value = 1
}

// 上传大货单，步骤 1 确认信息
const confirmForm = async () => {
  console.log('Form data:', form1.value)
  form1.value.materialList = childList.value
  if (form1.value.materialList.length === 0) {
    ElMessage({
      message: 'Please enter a material!',
      type: 'error'
    })
    return
  }
  const res = await updateQuotation(form1.value)
  if (res.code === 200) {
    nextStep()
  } else {
    ElMessage({
      message: res.msg,
      type: 'error'
    })
  }
}

// 关闭弹窗
const uploadDetailStep = ref(1)

const emit = defineEmits(['customEvent'])
const CloseDialog = () => {
  emit('customEvent', 'close')
}

// 上传大货单， 步骤 2 上传信息
const uploadDetail = async () => {
  console.log('Form data:', form2.value)
  form2.value.attachmentList = attachmentList.value
  form2.value.quotationId = props.quotationId
  const res = await saveTransportationInfo(form2.value)
  if (res.code === 200) {
    CloseDialog()
  } else {
    ElMessage({
      message: res.msg,
      type: 'error'
    })
  }
}

</script>

<style lang="scss" scoped>
.upload_detail {

    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    line-height: 1;
    text-align: left;
    overflow-y: scroll;

    &_container {
        width: 890px;
        margin: 80px auto 0;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 300px;

        &_title {
            display: flex;
            justify-content: space-between;
            padding: 30px;
            background-color: rgba(248, 248, 248, 1);

            P {
                font-size: 20px;
                margin: 0;
                font-weight: 700;
                color: rgba(24, 24, 24, 0.2);

                .active {
                    color: rgba(24, 24, 24, 1);
                }

                span {
                    cursor: pointer;

                    &:last-child {
                        margin-left: 50px;
                    }
                }
            }

            &_close {
                width: 20px;
                height: 20px;
                cursor: pointer;

            }
        }

        &_form {
            margin-top: 50px;

            &_item {
                ::v-deep .el-form {
                    width: 730px;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    column-count: 2;
                    /* 设置列数 */
                    column-gap: 20px;
                    /* 设置列间距 */
                    align-content: flex-start;
                    // justify-content: space-between;

                    .el-form-item {
                        margin-bottom: 30px;
                        width: 350px;
                        display: flex;
                        flex-direction: column;

                        .el-form-item__label {
                            width: 340px;
                            justify-content: flex-start;
                            font-size: 14px;
                            line-height: 1;
                            height: 14px;
                            margin-bottom: 10px;
                            color: rgba(24, 24, 24, 1);
                            font-weight: 300;
                        }

                        .el-form-item__content {
                            align-items: flex-start;

                            .el-input {
                                height: 46px;

                                .el-input__wrapper {
                                    padding: 0px 20px;
                                    box-shadow: none;
                                    background-color: rgba(249, 249, 249, 1);

                                    .el-input__inner {
                                        color: rgba(24, 24, 24, 1);
                                        font-size: 16px;

                                        &::placeholder {
                                            color: rgba(173, 173, 173, 0.6);
                                        }
                                    }
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }
                            }

                            .el-select {
                                width: 450px;

                                .el-input__wrapper.is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1) !important;
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                            }

                            .el-date-editor.el-input {
                                width: 400px;

                                .el-input__wrapper {
                                    padding: 25px 20px;
                                }
                            }

                            .el-upload {
                                width: 350px;
                            }

                            .el-upload-dragger {
                                padding: 0px 0;

                                .el-upload-dragger {
                                    height: 46px;
                                }
                            }
                        }

                        .el-button {
                            border-radius: 4px;
                            background-color: rgba(39, 174, 96, 1);
                            box-shadow: none;
                            border: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 700;
                            padding: 27px 30px;
                            margin: 0;
                        }

                        .back_btn {
                            background-color: rgba(255, 255, 255, 1);
                            color: rgba(39, 174, 96, 1);
                            margin-right: 50px;
                        }

                        &:last-child {
                            width: 890px;
                            justify-content: center;
                            align-items: center;
                            margin: 40px auto 60px;
                        }
                    }
                }

                .el-form-line {
                    width: 730px;
                    margin: 20px auto 50px;
                    height: 1px;
                    border-bottom: 1px dashed rgba(39, 174, 96, 0.5);
                }
                .add_material{
                  width: 730px;
                  margin-bottom: 20px;
                  font-size: 12px;
                  line-height: 1;
                  color: rgba(36, 89, 2, 1);
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  white-space: nowrap;
                }
                .el-form-line2 {
                    width: 730px;
                    margin: 20px auto 50px;
                    font-size: 14px;
                    line-height: 1;
                    color: rgba(36, 89, 2, 1);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        border-bottom: 1px dashed rgba(173, 173, 173, 0.6);
                    }

                    img {
                        width: 14px;
                        margin: 0 15px;
                    }

                    span {
                        padding-right: 30px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
</style>
