<template>
  <div class="amap">
    <div class="amap_container">
      <div class="amap_container_input">
        <div class="amap_container_input_left">
          <el-cascader :placeholder="placeholder" size="default" :options="regionData" v-model="selectedOptions" @change="handleChange">
          </el-cascader>
          <span>{{ address }}</span>
        </div>

        <div class="amap_container_input_btn" @click="CloseDialog"><span>确定</span>
          <span @click="CloseDialog">关闭</span>
        </div>
      </div>
      <div id="container" ref="container"></div>
    </div>
  </div>
</template>
<script setup name="Amap">
import AMapLoader from '@amap/amap-jsapi-loader'
import { regionData } from 'element-china-area-data'
import { ref, onMounted, defineEmits, defineExpose } from 'vue'
import { shallowRef } from '@vue/reactivity'
const map = shallowRef(null)
// "key": "f4cd24293f5594ffcda56bad70dcabb6"
// 添加高德安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '5ca65c74bfbcc9922df08400c8dba2d5'
}
const areaList = ref()
const address = ref('请点击地图选择具体地点')
const placeholder = ref('请选择地点')

// 设置placeholder
const setPlaceholder = (value) => {
  // console.log(value, '接收到的value')
  if (value === 'material') {
    placeholder.value = '请选择原料位置'
  } else if (value === 'Factory') {
    placeholder.value = '请选择工厂所在地'
  }
}
const location = ref([])
const handleChange = (value) => {
  let province = regionData.find(item => item.value === value[0])
  let city = province.children.find(item => item.value === value[1])
  let area = city.children.find(item => item.value === value[2])
  console.log(province.label, city.label, area.label)
  areaList.value = [province.label + city.label + area.label]
  // console.log(areaList.value, 'areaList');
  initMap()
}

const selectedOptions = ref([])
let distance = ref(0)


// AMap.PlaceSearch AMap.AutoComplete
const initMap = () => {
  AMapLoader.load({
    "key": "f4cd24293f5594ffcda56bad70dcabb6",
    "version": "2.0",
    "plugins": ["AMap.Driving", "AMap.Geocoder", "AMap.Marker"],
    AMapUI: {
      version: "1.1",
      plugins: [],

    },
    Loca: {
      version: "2.0.0"
    },
  }).then((AMap) => {
    map.value = new AMap.Map("container", {
      resizeEnable: true,
      // center: [lng.value, lat.value],//地图中心点
      zoom: 12 //地图显示的缩放级别
    });
    // 鼠标点击获取经纬度
    let marker = null; // 定义 marker 变量
    map.value.on('click', function (e) {
      // 通过经纬度获取位置名称
      const geocoder = new AMap.Geocoder();
      geocoder.getAddress(e.lnglat, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          var address1 = result.regeocode.formattedAddress;
          address.value = address1;
          console.log(address1, '地址');
          location.value = [e.lnglat.lng, e.lnglat.lat];
        } else {
          console.log('根据经纬度查询地址失败')
        }
      });

      if (marker) {
        map.value.remove(marker); // 如果已有标记，则删除已有标记
      }
      marker = new AMap.Marker({
        position: e.lnglat,
        map: map.value,
      });

    });

    // 根据选择的地区获取经纬度
    const geocoder = new AMap.Geocoder();
    geocoder.getLocation(areaList.value, function (status, result) {
      if (status === 'complete' && result.geocodes.length) {
        const { lng, lat } = result.geocodes[0].location;
        console.log(lng, lat, '转换后');
        map.value.setCenter([lng, lat]);
      } else {
        console.log('地址转换失败');
      }
    });

  }).catch(e => {
    console.log(e);
  })
}
onMounted(() => {
  initMap()
  setPlaceholder()
  console.log(placeholder.value, 'placeholder');
})

// 关闭弹窗
const emit = defineEmits(['customEvent'])
const CloseDialog = () => {
  emit('customEvent', { address, location })
}

defineExpose({
  setPlaceholder
})

</script>

<style lang="scss" scoped>
// @import url('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');

.amap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  line-height: 1;
  text-align: left;
  overflow-y: scroll;

  &_container {
    display: flex;
    flex-direction: column;

    &_input {
      width: 980px;
      margin: 50px auto 0;
      z-index: 12;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      backdrop-filter: blur(20px);

      &_left {
        font-size: 14px;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 500px;

        }
      }

      &_btn {
        span {
          display: inline-block;
          background-color: rgba(39, 174, 96, 1);
          font-size: 14px;
          line-height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          color: #fff;
          font-weight: 600;

          &:last-child {
            color: chocolate;
            background-color: #fff;
            margin-left: 10px;
          }
        }
      }

      :deep .el-input__wrapper {
        width: 240px;
        height: 40px;
      }
    }
  }

  #container {
    height: 650px;
    width: 1300px;
    margin: 0px auto 0;
    margin-top: -50px;
    z-index: 10;
  }

  #tipinput {
    width: 200px;
    height: 36px;
    padding: 0 10px;
    font-size: 14px;
  }

  #tipinput2 {
    width: 200px;
    height: 36px;
    padding: 0 10px;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
}

.custom-content-marker .close-btn:hover {
  background: #666;
}
</style>
