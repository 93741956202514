<template>
  <div class="container-b">
    <div class="container-b_banner">
      <el-carousel class="carousel-banner" @change="bannerChange" :autoplay="true" :interval="5000" :pause-on-hover="false">
        <el-carousel-item>
          <div class="box">
            <img class="bg" src="../assets/images/banner/banner1_bg.jpg" alt="" style="height: 500px;">
            <img :class="{ active: imgIndex === 0, text: true }" src="../assets/images/banner/banner1_text.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="box">
            <img class="bg" src="../assets/images/banner/banner2_bg.jpg" alt="" style="height: 500px;">
            <img :class="{ active: imgIndex === 1, text: true }" src="../assets/images/banner/banner2_text.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="box">
            <img class="bg" src="../assets/images/banner/banner3_bg.jpg" alt="" style="height: 500px;">
            <img :class="{ active: imgIndex === 2, text: true }" src="../assets/images/banner/banner3_text.png" alt="">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script setup name="Banner">
import { ref } from 'vue'
const imgIndex = ref(0)

const bannerChange = (index) => {
  // console.log('bannerChange', index)
  imgIndex.value = index
}
</script>

<style lang="scss" scoped>
.container-b {
  width: 100%;

  &_banner {
    .carousel-banner {
      height: 500px;
    }
  }

  :deep .el-carousel__item {
    // background-color: aquamarine;
    height: 500px;

    .box {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .text {
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 150px;
      transform: translate(0px, 150px);
      opacity: 0;
    }

    .active {
      transform: translate(0px, 0px);
      transition: all 1.5s;
      transition-delay: 1s;
      opacity: 100;
    }

  }
}
</style>
